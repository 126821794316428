import { getRequest, postRequest, putRequest } from "@/utils/request";

//订单确认
export const orderConfirm = (params) => {
  return postRequest("order/confirm", params);
};
//订单确认
export const confirmV2 = (params) => {
  return postRequest("order/confirmV2", params);
};
//订单创建
export const orderCreate = (data, params) => {
  return postRequest("order/create/" + data, params);
};
//订单创建
export const createV2 = (data, params) => {
  return postRequest("order/createV3/" + data, params);
};
//订单详情
export const orderDetail2 = (params, username) => {
  return getRequest("order/detail/" + params + "?store_name=" + username);
};
//线下支付
export const orderPay = (data, params) => {
  return postRequest("order/offline_pay/" + data, params);
};

//已发货配送单
export const deliverProduct = (data) => {
  return getRequest("order/deliverProduct/" + data);
};
//待推送配送单
export const nonedDeliverProduct = (data) => {
  return getRequest("order/nonedDeliverProduct/" + data);
};
//确认收货
export const takeDistribution = (data) => {
  return postRequest("order/take_distribution/" + data);
};
//订单列表
export const orderList = (params) => {
  return putRequest("order/list", params);
};
//取消订单
export const orderCancel = (data) => {
  return getRequest("order/cancel/" + data);
};
//修改收货地址
export const editAddress = (params, data) => {
  return postRequest("order/edit_address/" + params, data);
};
//上传付款凭证
export const uploadCertificate = (id, params) => {
  return postRequest("order/upload_certificate/" + id, params);
};
//订单数据统计
export const orderDataStatistics = (params) => {
  return getRequest("order/order_data_statistics", params);
};
//批量配送单收货
export const batchTakeDistribution = (params) => {
  return postRequest("order/batch_take_distribution", params);
};
//线上支付
export const payOrder = (params) => {
  return postRequest("order/pay_order/" + params);
};
//已收货/完成配送单
export const finishDistribution = (params) => {
  return getRequest("order/finishDistribution/" + params);
};

//上传图片
export const uploadImg = (params) => {
  return postRequest("pc/upload/image", params);
};

// //退款订单详情
// export const refundDetail = (params) => {
//     return getRequest('order/refundDetail/' +params)
// }

//线下付款
export const offlinePayV2 = (id, data) => {
  return postRequest("order/offline_pay_v2/" + id, data);
};

//收款账号
export const getBankAll = (params) => {
  return getRequest("order/getBankAll", params);
};
//收款账号
export const yuePay = (id, params) => {
  return postRequest("order/yuePay/" + id, params);
};
// 订单列表
export const getListV3 = (params) => {
  return getRequest("order/getListV3", params);
};
//订单详情
export const orderDetail = (id) => {
  return getRequest("order/getOrderDetail/" + id);
};
//售后规则
export const afterRules = () => {
  return getRequest("order/afterRules");
};
//售后原因
export const refundReason = () => {
  return getRequest("refund/refund_reason");
};
//申请售后
export const applyRefund = (data) => {
  return postRequest("refund/applyRefundv2", data);
};
//售后列表
export const refundList = (data) => {
  return getRequest("refund/list", data);
};
//发货信息
export const getDeliverInfo = (id) => {
  return getRequest("order/getDeliverInfo/" + id);
};
//确认收货
export const sureTake = (id) => {
  return postRequest("order/takev2/" + id);
};
//查询快递信息
export const orderExpress = (data) => {
  return getRequest("order/get_express_info", data);
};
//完善物流信息
export const expressDelivery = () => {
  return getRequest("refund/expressDelivery");
};
//售后详情
export const refundDetail = (id) => {
  return getRequest("refund/detail/" + id);
};
//完善物流信息
export const expressWaybill = (data) => {
  return postRequest("refund/expressWaybill", data);
};

//再来一单
export const buyAgain = (data) => {
  return getRequest("order/againBuy", data);
};

//取消售后
export const cancelRefundApi = (data) => {
  return postRequest("refund/cancel", data);
};
